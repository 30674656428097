@import '../../../../scss/theme-bootstrap';

.invis-search {
  &__close#cboxClose {
    display: none;
  }
  &__row {
    padding: 10px 20px;
    @media #{$large-up} {
      @include swap_direction(padding, 0 0 10px 0);
    }
  }
  &__header {
    padding-bottom: 10px;
  }
  &__container {
    position: relative;
    height: 100%;
    overflow: hidden;
    .no_results_found {
      padding: 0 20px;
      width: 45%;
      float: #{$rdirection};
    }
    .invis-result {
      &__container {
        height: 100%;
        &-offer_msg {
          padding-top: 6px;
          clear: both;
        }
      }
    }
    .invis-results {
      padding: 0 25px;
      @media #{$large-up} {
        float: #{$rdirection};
        width: 35%;
        padding: 0;
      }
      &__map {
        display: block;
        height: 400px;
        margin-bottom: 15px;
        @media #{$large-up} {
          float: #{$ldirection};
          margin-bottom: 0;
          width: 64%;
          height: 350px;
        }
      }
      &__map.has-results {
        height: 200px;
        @media #{$large-up} {
          height: 500px;
          width: 64%;
        }
      }
      &__messages {
        text-transform: uppercase;
        color: $color-red;
        padding-bottom: 10px;
      }
      &__list-wrapper {
        height: 200px;
        @media #{$large-up} {
          height: 500px;
        }
      }
      &__list {
        height: 100%;
        overflow: scroll;
        overflow-x: hidden;
        /* Webkit */
        &::-webkit-scrollbar {
          -webkit-appearance: none;
        }
        &::-webkit-scrollbar:vertical {
          width: 3px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: $color-darker-gray;
        }
        &::-webkit-scrollbar-track {
          background-color: $color-lighter-gray;
        }
        .invis-result {
          padding: 10px 10px 0;
          letter-spacing: 0.1em;
          &__inv-status {
            text-transform: uppercase;
            color: $color-periwinkle-blue;
          }
          &.has-inventory {
            .invis-result__inv-status {
              color: $color-red;
            }
          }
          &__store-name {
            margin: 0 0 5px;
          }
          &__address {
            margin: 5px 0;
          }
          &__marker {
            float: #{$ldirection};
            margin-#{$ldirection}: 10px;
          }
          &__info {
            padding-#{$ldirection}: 40px;
          }
          &__distance {
            font-size: 14px;
            padding-#{$ldirection}: 10px;
          }
          &__directions a {
            text-decoration: underline;
            text-transform: uppercase;
            font-weight: bold;
          }
          &.no-inventory a {
            color: $color-dark-gray;
          }
          &.selected {
            background-color: $color-gray-cs-callout;
          }
        }
      }
    }
    .invis-info {
      border: none;
      font-size: 15px;
      font-family: $akzidenz;
      padding: 5px;
      overflow: hidden;
      &__store-name {
        font-size: 22px;
      }
      &__address {
        padding-top: 10px;
      }
    }
    .gm-ui-hover-effect {
      opacity: 0;
    }
    .invis-form {
      &__select--city {
        vertical-align: top;
        height: 40px;
        line-height: 3.3;
        width: 100%;
        @media #{$large-up} {
          width: 60%;
          float: #{$ldirection};
        }
        @media #{$device-ipad_only} {
          @media #{$landscape} {
            width: 50%;
          }
        }
      }
      &__select--radius {
        vertical-align: top;
        height: 40px;
        line-height: 3.3;
        margin-left: 10px;
      }
      &__btn--submit {
        @include swap_direction(margin, 10px 0 0 0);
        width: 100%;
        @media #{$large-up} {
          @include swap_direction(margin, 0 0 0 10px);
          width: 35%;
        }
        @media #{$device-ipad_only} {
          @media #{$landscape} {
            width: 49%;
            margin: auto;
          }
        }
      }
    }
    .ajax-spinner {
      margin: 10px auto 0;
    }
    .invis-sku__container {
      width: 100%;
      font-family: $akzidenz;
      font-size: 14px;
      @media #{$large-up} {
        width: 65%;
        float: #{$ldirection};
      }
      .invis-sku {
        &__product--info {
          position: relative;
          margin-bottom: 10px;
          min-height: 90px;
        }
        &__image {
          display: inline-block;
          max-width: 20%;
          margin-#{$rdirection}: 20px;
        }
        &__name {
          width: 67%;
          display: inline-block;
          vertical-align: top;
          font-size: 14px;
          letter-spacing: 0.15em;
          line-height: 1.3;
          font-weight: bold;
        }
        &__product--size {
          position: absolute;
          #{$ldirection}: 85px;
          bottom: 7%;
          text-transform: uppercase;
          font-size: 12px;
        }
        &__prodname {
          display: block;
        }
        &__shadecolor {
          width: 20px;
          height: 20px;
          border-radius: 100%;
          #{$ldirection}: 0;
          position: absolute;
        }
        &__shadename {
          #{$ldirection}: 30px;
          position: relative;
          line-height: 1.8;
        }
      }
    }
    .invis-form__container {
      width: 100%;
      @media #{$large-up} {
        width: 35%;
        float: #{$rdirection};
      }
      &.bopis {
        clear: left;
        .invis-form__btn--submit {
          width: 25%;
        }
      }
    }
    &.bopis {
      margin: 20px;
      @media #{$small-only} {
        margin: 0;
        overflow: auto;
      }
      .invis {
        &-search__header {
          padding-bottom: 0;
          @media #{$large-up} {
            font-size: 30px;
          }
          @media #{$small-only} {
            font-size: 32px;
            text-align: center;
          }
        }
        &-search__subtitle {
          @media #{$small-only} {
            text-align: center;
          }
        }
        &-search__row {
          padding: 10px 0;
          .invis-sku__container {
            @media #{$large-up} {
              width: 100%;
              float: #{$ldirection};
            }
          }
          .invis-form__btn--add {
            @media #{$small-only} {
              margin-left: 24%;
            }
          }
          .invis-form__btn--submit {
            width: 142px;
            margin: 0 0 0 10px;
          }
        }
        &-form__container {
          width: 45%;
          @media #{$small-only} {
            width: 100%;
            margin: 10px 0;
            .invis-form__select--city,
            .invis-form__btn--submit {
              width: 100%;
              margin: 5px 0;
            }
          }
        }
        &-results {
          @media #{$small-only} {
            padding: 0;
            &__container {
              height: auto;
            }
          }
          &__list {
            background-color: $color-gray-cs-callout;
            @media #{$small-only} {
              overflow: visible;
              &-wrapper {
                height: 100%;
                overflow: visible;
              }
            }
          }
        }
      }
    }
  }
}

.invis-start {
  &__panel {
    clear: both;
    padding-top: 15px;
    a {
      text-decoration: underline;
      text-transform: uppercase;
    }
  }
  &__msg {
    background-color: $color-gray-cs-callout;
    padding: 12px;
    &__doorinfo {
      display: block;
      clear: both;
      &-name {
        font-weight: bold;
      }
      &-distance {
        color: $color-darker-gray;
      }
    }
    &--available {
      display: block;
      color: $color-periwinkle-blue;
      text-transform: uppercase;
      float: right;
      font-weight: bold;
    }
  }
  &__link--open-store {
    cursor: pointer;
  }
  &__link--open-results {
    display: inline-block;
  }
  &__link--open-finder {
    text-align: center;
    display: block;
    font-weight: bold;
    @media #{$large-up} {
      text-align: #{$ldirection};
    }
  }
}

.invis-overlay {
  #cboxContent {
    padding: 0;
    @media #{$large-up} {
      padding: 25px;
    }
  }
}

body.ajax-wait *,
body.ajax-wait {
  cursor: progress !important;
}

@media #{$medium-up} {
  .bopis-overlay {
    overflow: hidden;
    #cboxLoadedContent {
      overflow: hidden !important;
    }
  }
}

.bopis-spp-container {
  margin: 12px 0;
  .has_nearest_store {
    .spp-shipping-head {
      padding-top: 12px;
    }
    .invis-start {
      &__msg {
        margin-top: 10px;
      }
    }
  }
  .spp-shipping-icon {
    width: 30px;
    float: left;
    margin: 0 3px;
    background-image: url('/media/export/cms/two_hour_delivery/shipping_icon.png');
    height: 25px;
    background-size: 25px;
    background-repeat: no-repeat;
  }
  .spp-bopis-icon {
    width: 25px;
    float: left;
    margin: 5px;
    background-image: url('/media/export/cms/two_hour_delivery/pick-up-icon.png');
    height: 25px;
    background-size: 25px;
    background-repeat: no-repeat;
  }
  .spp-shipping-head {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    display: block;
  }
  .free-shipping-more-info {
    text-transform: uppercase;
    text-decoration: underline;
  }
  .bopis-more-info::after {
    content: '?';
    display: inline-block;
    font-weight: bold;
    text-align: center;
    width: 15px;
    height: 15px;
    font-size: 10px;
    line-height: 13px;
    border-radius: 15px;
    border: 1px solid $color-navy;
    text-decoration: none;
    cursor: pointer;
  }
}

.bopis-spp-separator {
  height: 10px;
}

.brand-renutriv {
  .invis-start {
    &__msg {
      background-color: transparent;
    }
  }
  .bopis-more-info::after {
    border: 1px solid $color-white;
  }
  .spp-bopis-icon {
    background-image: url('/media/export/cms/two_hour_delivery/pick-up-icon_renutriv.png');
  }
  .spp-shipping-icon {
    background-image: url('/media/export/cms/two_hour_delivery/shipping_icon_renutriv.png');
  }
}
